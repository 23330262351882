import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { translate, TranslatorProvider } from 'react-translate';
import { CardText, CardTitle, Collapse, Row } from 'reactstrap';
import { tokenName } from 'configs/app.json';
import storageDispatcher from "helpers/storageDispatcher";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
//hooks
import useInitialUrl from 'hooks/useInitialUrl';
// core components
import { actionLogin } from 'store/actions/usersActions';
import LegalContent from 'views/Components/LegalContent';

const LoginPage = (props) => {
  const { t, history } = props;
  const { language } = useSelector((state) => state.userReducer);
  const isMounted = useRef();
  const urlDefault = useInitialUrl();

  const [openDebug, setOpenDebug] = useState(false);

  const { data } = useParams();
  const credentials = data
    ? decodeURIComponent(escape(atob(data))).split(':')
    : null;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);

  const [usernameFocus, setUsernameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
    usernameState: '',
    passwordState: '',
  });

  //Validations
  const loginEmail = (e) => {
    let login = {
      username: null,
      usernameState: null,
    };

    login.username = e.target.value.toLowerCase();
    if (e.target.value.length > 4) {
      login.usernameState = 'has-success';
    } else {
      login.usernameState = 'has-danger';
    }
    setLoginForm({ ...loginForm, ...login });
  };

  const loginPassword = (e) => {
    let login = {
      password: null,
      passwordState: null,
    };
    login.password = e.target.value;
    if (e.target.value.length > 2) {
      login.passwordState = 'can-continue';
    }  /*else {
      login.passwordState = 'has-danger';
    }
    */
    setLoginForm({ ...loginForm, ...login });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      loginForm.usernameState === 'has-success' &&
      loginForm.passwordState === 'can-continue'
    ) {
      storageDispatcher.setLocalStorageEvent("pending_initial_choice", true);
      
      await dispatch(
        actionLogin({
          username: loginForm.username,
          password: loginForm.password,
        })
      );
    }
  };

  useEffect(() => {
    if (data) {
      storageDispatcher.removeLocalStorageEvent("pending_initial_choice");
      dispatch(
        actionLogin({
          username: credentials[0],
          password: credentials[1],
        })
      );
    }
  }, [data]);

  const isAuth = () => {
    if (user.isAuth) {
      const storedUrl = localStorage.getItem('tsp-url');

      //Create exception for lab-studies and inbox
      if (storedUrl && (storedUrl.includes("lab-studies") || storedUrl.includes("inbox"))) {
        storageDispatcher.removeLocalStorageEvent("pending_initial_choice");
      }

      if (!user.on_delete && localStorage.getItem("pending_initial_choice")) {
        props.history.push("/admin/initial");
        localStorage.removeItem('tsp-url');
      } else if (localStorage.getItem(tokenName)) {

        if (!['/admin/initial','/admin/dashboard'].includes(urlDefault)) {
          storageDispatcher.removeLocalStorageEvent("pending_initial_choice");
        }
        
        props.history.push(
          user.on_delete ? 
          "/admin/delete-confirmation" : 
          !user.is_ready ? 
          "/admin/profile" : 
          user.tokenInfo ?
          "/admin/lab-studies" :
          urlDefault);
          
        localStorage.removeItem('tsp-url');
      }
    }
  };

  useEffect(() => {
    document.body.classList.add('login-page');
    isAuth();
    return () => {
      document.body.classList.remove('login-page');
    };
    // eslint-disable-next-line
  }, [user.isAuth]);

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current && credentials) {
      setLoginForm({
        usernameState: 'has-success',
        passwordState: 'has-success',
        username: credentials[0],
        password: credentials[1],
      });
      localStorage.setItem('ts-temp-secret', credentials[1]);
      setPasswordFocus(true);
    }
    return () => (isMounted.current = false);
    //eslint-disable-next-line
  }, [isMounted.current]);

  return (
    <Fragment>
      <div className="content">
        <picture>
          <source
            srcSet="/General/login/ilustracion.svg"
            media="(min-width: 720px)"
          />
          <img
            src="/General/login/illustration-mobile.svg"
            width="100%"
            alt="TelePraxen"
            className="mt-5"
          />
        </picture>
        <div className="login-page">

          {/*hidden information about the environment*/}
          {/* 23/11/2022 se agrego el className d-none para que no se vea la info de debug en el ambiente demo por favor no pisar ese cambio al hacer merge */}
          <div className={(process.env.REACT_APP_ENV_NAME == "demo") || (process.env.REACT_APP_ENV_NAME == "preprod") ? "debug-info d-none" : "debug-info"} style={{ color: "black" }}>
            <div
              onClick={(e) => {
                e.preventDefault();
                setOpenDebug(!openDebug);
              }
              }
            >
              <span>
                <b className="caret" />
              </span>
            </div>

            <Collapse isOpen={openDebug}>
              <div className="debug-url">
                {process.env.REACT_APP_HOST}
              </div>
            </Collapse>
          </div>
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form onSubmit={handleSubmit}>
                <Card className="card-login card-plain">
                  <CardHeader className="login-title">
                    <img src={'/General/login/logo.svg'} width="100%" alt="" />
                    <CardTitle className="text-info">{t('login')}</CardTitle>
                    <div className='login-subtitle'>{t('only-registered-patiens')}</div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        (usernameFocus ? ' input-group-focus ' : '') +
                        loginForm.usernameState
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={'/General/login/user.svg'}
                            width="15"
                            alt=""
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder={t('username') + '...'}
                        onFocus={(e) => setUsernameFocus(true)}
                        onBlur={(e) => setUsernameFocus(false)}
                        onChange={loginEmail}
                        name="login_username"
                        defaultValue={credentials ? credentials[0] : ''}
                      />
                    </InputGroup>
                    <InputGroup
                      className={
                        (passwordFocus ? ' input-group-focus ' : '') +
                        loginForm.passwordState +
                        ' mb-0'
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={'/General/login/password.svg'}
                            width="15"
                            alt=""
                          />
                          {/* <i className="now-ui-icons text_caps-small" /> */}
                        </InputGroupText>
                      </InputGroupAddon>
                        <Input
                          type={`${showPassword ? "text" : "password"}`}
                          placeholder={t('password') + '...'}
                          onFocus={(e) => setPasswordFocus(true)}
                          onBlur={(e) => setPasswordFocus(false)}
                          onChange={loginPassword}
                          name="login_password"
                          defaultValue={credentials ? credentials[1] : ''}
                        />

                      <InputGroupAddon 
                        addonType="append" 
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <InputGroupText>
                          <span className={`icon-${showPassword ? "invisible" : "show"}`} />
                        </InputGroupText>
                      </InputGroupAddon>

                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    {/* Switch to remember the pass */}
                    <FormGroup check>
                      <Label check>
                        <input
                          name="remember"
                          className="form-control"
                          type="checkbox"
                        />{' '}
                        <span className="form-check-sign mr-4 mb-3" />
                        <div className="keepme-text">{t('keepme')}</div>
                      </Label>
                    </FormGroup>
                    <Button
                      block
                      name="btn_login"
                      type="submit"
                      color="info"
                      size="sm"
                      className="mb-3 mt-3"
                    >
                      {t('login')}
                    </Button>
                    {/*
                    <div className="pull-left">
                      <Link
                        to="/auth/register-page"
                        className="link footer-link"
                      >
                        {t('create-account')}
                      </Link>
                    </div>
                    */}
                    <div className="login-form-bottom">
                      {/*
                      <Link
                        to="/auth/register-page"
                        className="link link-flex footer-link"
                      >
                        {t('create-account')}
                      </Link>
                      */}
                      <Link
                        name="link_pass_recovery"
                        to="/auth/forgot-password"
                        className="link footer-link link-right"
                      >
                        {t('need-help')}
                      </Link>
                    </div>
                    
                  </CardFooter>
                  
                  
                </Card>
              </Form>
              
            </Col>

            <div className='login-BOSSection'>
              <Row>

                <Col sm={12} className="mb-4">
                  <div>{t('are-you-not-registered')}</div>
                  <div>
                  <a href="#" name="link_registration" className="link" onClick={() => {  
                        window.open("http://termin-service.org");
                      }}>{t("registration")}</a>
                  </div>
                </Col>

                <Col sm={12}>
                  <div >{t('need-help-login')}</div>

                  <div className='mb-2'>

                  <Link
                        to="/auth/login-help"
                        className="link"
                        name="link_help"
                      >
                        {t("need-help-login-button")}
                  </Link>
                  </div>
                </Col>
              </Row>
            </div>

            <Row className="ml-auto mr-auto d-none d-sm-block justify-content-center">
              <TranslatorProvider translations={require(`assets/i18n/terms/${language || 'en'}.json`)}>
                <LegalContent />
              </TranslatorProvider>
            </Row>
          </Container>
        </div>
      </div>
      {/* <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      /> */}
    </Fragment>
  );
};

export default translate('login-page')(LoginPage);
