import React, { useEffect, useRef } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { tokenName } from 'configs/app.json';
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import history from 'historyRouter';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetUser, actionLogoutBase } from 'store/actions/usersActions';
import useNativeReceiver from "hooks/useNativeReceiver";
import Loading from 'plugins/TS-lib-utils-public/components/Loading/LoadingGlobal';

// react plugin for creating notifications
import { toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actionSetLoading } from "store/actions/loadingActions";

//Let toast plugin inject a ToastContainer automatically
toast.configure({
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  transition: Flip
})

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const loading = useSelector((state) => state.loadingReducer);
  const appInfo = useNativeReceiver();

  const isMounted = useRef(false);

  //Set url of request if the site is not mounted
  if (!isMounted.current && !loading && !user?.isAuth && !localStorage.getItem('tsp-url')) {
    localStorage.setItem('tsp-url', window.location.pathname);
  }

  let token = localStorage.getItem(tokenName);
  useEffect(() => {
    isMounted.current = true;
    if (token && !user?._id) {
      dispatch(actionSetLoading(true));
      dispatch(actionGetUser());
    }
  }, [dispatch, user?._id, token]);

  useEffect(() => {
    const action = appInfo.action;

    switch (action) {
      case "LOGOUT_READY":
        dispatch(actionLogoutBase());
        break;
    }
  }, [appInfo]);

  if ((token && !user?._id) || loading) {
    return <Loading loading />;
  }

  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/admin"
          render={(props) => {
            return <AdminLayout {...props} />;
          }}
        />
        <Route
          path="/auth"
          render={(props) => {
            return <AuthLayout {...props} />;
          }}
        />
        {token && user?._id ? (
          <Redirect from="/" to="/admin" />
        ) : (
          <Redirect from="/" to="/auth/login-page" />
        )}
      </Switch>
    </Router>
  );
};

export default App;
