import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-translate";
// reactstrap components
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from "reactstrap";
import history from 'historyRouter';
// core components
const Dashboard = () => {
  const { mobile } = useSelector((state) => state.commonReducer);
  const tIframe = useTranslate("sidebar");
  const tLaboratory = useTranslate('laboratory-studies');
  const tProfile = useTranslate('profile');
  const tInbox = useTranslate('messages');
  const laboratoryNotifications = useSelector(
    (state) => state.notificationsReducer["laboratory"]
  );
  const messageNotifications = useSelector(
    (state) => state.notificationsReducer["messages"]
  );

  return (
    <Fragment>
      <div className="content">
        <Card>

          <CardHeader>
            <CardTitle style={{marginBottom: "50px"}} className="d-flex align-content-center">
                <div className="general-card-title-img text-primary mr-2">
                  <i className="icon-d" />
                </div>
                <div className="general-card-title text-primary">
                  {"Home"}
                </div>
            </CardTitle>
          </CardHeader>

          <CardBody>
            <div className="d-flex justify-content-center">
              <Row className="justify-content-around" style={{maxWidth: "900px", width: "100%"}}>
                <Col xs={6} className="d-flex justify-content-center">
                  <Card onClick={() => {history.push(`/admin/frametest`)}} className="dashboard-button">
                    <CardHeader>
                      <CardTitle tag="h2" className="d-flex align-items-center justify-content-center">
                        <span className="text-info icon icon-appointments" style={{fontSize: "100px"}}></span>
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="d-flex justify-content-center">
                      <div className="dashboard-text">{tIframe("iframe")}</div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={6} className="d-flex justify-content-center">
                  <Card onClick={() => {history.push(`/admin/inbox`)}} className="dashboard-button">
                    {messageNotifications.unreaded > 0 ? (
                      <span className={`widget-notification-counter${messageNotifications.unreaded > 99 ? '-plus' : ''}`}>
                        {messageNotifications.unreaded > 99 ? "99" : messageNotifications.unreaded}
                      </span>
                    ) : (<></>)}
                    <CardHeader>
                      <CardTitle tag="h2" className="d-flex align-items-center justify-content-center">
                        <span className="text-info icon icon-inbox-header mr-2" style={{fontSize: "100px"}}></span>
                      </CardTitle>
                    </CardHeader>

                    <CardBody className="d-flex justify-content-center">
                      <div className="dashboard-text">{tInbox('title')}</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-center">
              <Row className="justify-content-center mt-3" style={{maxWidth: "900px", width: "100%"}}>
                <Col xs={6} className="d-flex justify-content-center">
                  <Card onClick={() => {history.push(`/admin/lab-studies`)}} className="dashboard-button">
                    {laboratoryNotifications.unreaded > 0 ? (
                      <span className={`widget-notification-counter${laboratoryNotifications.unreaded > 99 ? '-plus' : ''}`}>
                        {laboratoryNotifications.unreaded > 99 ? "99" : laboratoryNotifications.unreaded}
                      </span>
                    ) : (<></>)}
                    <CardHeader>
                      <CardTitle tag="h2" className="d-flex align-items-center justify-content-center">
                        <span className="text-info icon icon-microscope" style={{fontSize: "100px"}}></span>
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="d-flex justify-content-center">
                      <div className="dashboard-text">{tLaboratory('laboratory-studies')}</div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={6} className="d-flex justify-content-center">
                  <Card onClick={() => {history.push(`/admin/profile`)}} className="dashboard-button">
                    <CardHeader>
                      <CardTitle tag="h2" className="d-flex align-items-center justify-content-center">
                        <span className="text-info icon-user-active" style={{fontSize: "100px"}}></span>
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="d-flex justify-content-center">
                      <div className="dashboard-text">{tProfile("title")}</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default Dashboard;
