const CONSTANTS = {
  //App
  RESET_APP: "RESET_APP",
  //Common
  SWEET_ALERT: "SWEET_ALERT",
  MOBILE_VERSION: "MOBILE_VERSION",
  SET_AVATARS: 'SET_AVATARS',
  PUSH_AVATAR: 'PUSH_AVATAR',
  REMOVE_AVATARS: 'REMOVE_AVATARS',
  NATIVE_WEBVIEW: "NATIVE_WEBVIEW",
  //WEBSOCKETS
  USERS_FORCE_LOGOUT: "USERS_FORCE_LOGOUT",
  WS_NOTIFICATION: "WS_NOTIFICATION",
  //Doctors
  DOCTORS_SET_LIST: "DOCTORS_SET_LIST",
  DOCTORS_GET_LIST: "DOCTORS_GET_LIST",
  //Patients
  PATIENTS_GET_LIST: "PATIENTS_GET_LIST",
  PATIENTS_SET_LIST: "PATIENTS_SET_LIST",
  PATIENTS_GET: "PATIENTS_GET",
  PATIENTS_SET: "PATIENTS_SET",
  PATIENTS_SAVE: "PATIENTS_SAVE",
  PATIENTS_EDIT: "PATIENTS_EDIT",
  PATIENTS_DELETE: "PATIENTS_DELETE",
  //Address
  ADDRESS_SAVE: "ADDRESS_SAVE",
  ADDRESS_DELETE: "ADDRESS_DELETE",
  //Messages
  MESSAGES_GET_INBOX: "MESSAGES_GET_INBOX",
  MESSAGES_SET_INBOX: "MESSAGES_SET_INBOX",
  MESSAGES_SET_INBOX_SCROLL: "MESSAGES_SET_INBOX_SCROLL",
  MESSAGES_GET_MSG: "MESSAGES_GET_MSG",
  MESSAGES_GET_MSG_NOTIFICATION: "MESSAGES_GET_MSG_NOTIFICATION",

  MESSAGES_GET_OUTBOX: "MESSAGES_GET_OUTBOX",
  MESSAGES_SET_OUTBOX: "MESSAGES_SET_OUTBOX",
  MESSAGES_GET_OUTBOX_MSG: "MESSAGES_GET_OUTBOX_MSG",
  MESSAGES_SET_OUTBOX_SCROLL: "MESSAGES_SET_OUTBOX_SCROLL",

  MESSAGES_GET_PRIORITY: "MESSAGES_GET_PRIORITY",
  MESSAGES_SET_PRIORITY: "MESSAGES_SET_PRIORITY",
  MESSAGES_SET_PRIORITY_BOX: "MESSAGES_SET_PRIORITY_BOX",
  MESSAGES_DELETE_PRIORITY: "MESSAGES_DELETE_PRIORITY",
  MESSAGES_SET_PRIORITYBOX_SCROLL: "MESSAGES_SET_PRIORITYBOX_SCROLL",

  MESSAGES_GET_SCROLL: "MESSAGES_GET_SCROLL",
  MESSAGES_LOADING_INBOX: "MESSAGES_LOADING_INBOX",
  MESSAGES_SET_MSG: "MESSAGES_SET_MSG",
  MESSAGES_DEL_MSG: "MESSAGES_DEL_MSG",
  MESSAGES_SEND_MSG: "MESSAGES_SEND_MSG",
  MESSAGES_LOADING_MSG: "MESSAGES_LOADING_MSG",
  MESSAGES_SET_BROWSER: "MESSAGES_SET_BROWSER",
  MESSAGES_GET_SELECTED: "MESSAGES_GET_SELECTED",
  MESSAGES_SET_SELECTED: "MESSAGES_SET_SELECTED",

  MESSAGES_SET_BOX: "MESSAGES_SET_BOX",
  MESSAGES_REFRESH_BOXES: "MESSAGES_REFRESH_BOXES",
  USERS_GET_LIST: "USERS_GET_LIST",
  USERS_SET_LIST: "USERS_SET_LIST",
  //Appointments
  APPOINTMENTS_GET: "APPOINTMENTS_GET",
  APPOINTMENTS_SET: "APPOINTMENTS_SET",
  APPOINTMENTS_SET_AP: "APPOINTMENTS_SET_AP",
  APPOINTMENTS_GET_AP: "APPOINTMENTS_GET_AP",
  //Users
  USERS_LOGIN: "USERS_LOGIN",
  USERS_LOGOUT: "USERS_LOGOUT",
  USERS_SHOW_LOGOUT_MODAL: 'USERS_SHOW_LOGOUT_MODAL',
  USERS_LOGOUT_BASE: "USERS_LOGOUT_BASE",
  USERS_LOGOUT_CLEAR: "USERS_LOGOUT_CLEAR",
  USERS_CHECK_SESSION: "USERS_CHECK_SESSION",
  USERS_REGISTER: "USERS_REGISTER",
  USERS_GET: "USERS_GET",
  USERS_SAVE: "USERS_SAVE",
  USERS_SET: "USERS_SET",
  USERS_EDIT: "USERS_EDIT",
  USERS_LOADING: "USERS_LOADING",
  USERS_REFRESH_DATA: "USERS_REFRESH_DATA",
  USERS_GET_SESSIONS: "USERS_GET_SESSIONS",
  USERS_SET_SESSIONS: "USERS_SET_SESSIONS",
  USERS_DELETE_SESSION: "USERS_DELETE_SESSION",
  USERS_CHANGE_PASSWORD: "USERS_CHANGE_PASSWORD",
  USERS_RECOVER_PASSWORD: "USERS_RECOVER_PASSWORD",
  USERS_DELETE_ACCOUNT_REQUEST: "USERS_DELETE_ACCOUNT_REQUEST",
  USERS_DELETE_ACCOUNT_PREPARE: "USERS_DELETE_ACCOUNT_PREPARE",
  USERS_DELETE_ACCOUNT: "USERS_DELETE_ACCOUNT",
  USERS_SEND_HELP: "USERS_SEND_HELP",
  //Users settings
  USERS_SET_LANG: "USERS_SET_LANG",
  USERS_UPDATE_LANG: "USERS_UPDATE_LANG",
  USERS_SET_DATE_FORMAT: "USERS_SET_DATE_FORMAT",
  USERS_UPDATE_DATE_FORMAT: "USERS_UPDATE_DATE_FORMAT",
  //User Profile
  PROFILE_GET: "PROFILE_GET",
  PROFILE_SAVE: "PROFILE_SAVE",
  PROFILE_SET: "PROFILE_SET",
  PROFILE_EDIT: "PROFILE_EDIT",
  PROFILE_LOADING: "PROFILE_LOADING",
  //Auth2fa
  AUTH2FA_CREATE: "AUTH2FA_CREATE",
  AUTH2FA_SET: "AUTH2FA_SET",
  AUTH2FA_ACTIVATE: "AUTH2FA_ACTIVATE",
  AUTH2FA_INACTIVATE: "AUTH2FA_INACTIVATE",
  AUTH2FA_CHECK: "AUTH2FA_CHECK",
  //Responses
  RESPONSE: "RESPONSE",
  RESPONSE_CLEAN: "RESPONSE_CLEAN",
  //Loading
  LOADING: "LOADING",
  //WS
  WS_RECEIVE_ACTION: "WS_RECEIVE_ACTION",
  WS_ALERT: "WS_ALERT",
  WS_EMIT_ACTION: "WS_EMIT_ACTION",
  WS_EMIT_TO_USER: "WS_EMIT_TO_USER",
  WS_EMIT_TO_APP: "WS_EMIT_TO_APP",
  WS_EMIT_TO_ALL: "WS_EMIT_TO_ALL",
  //Notifications
  NOTIFICATIONS_GET: "NOTIFICATIONS_GET",
  NOTIFICATIONS_MESSAGE_SET: "NOTIFICATIONS_MESSAGE_SET",
  NOTIFICATIONS_CHAT_SET: "NOTIFICATIONS_CHAT_SET",
  NOTIFICATIONS_LAB_SET: "NOTIFICATIONS_LAB_SET",
  NOTIFICATIONS_UPDATE: "NOTIFICATIONS_UPDATE",
  NOTIFICATIONS_READ: "NOTIFICATIONS_READ",
  NOTIFICATIONS_READBYMESSAGES: "NOTIFICATIONS_READBYMESSAGES",
  NOTIFICATIONS_READALL: "NOTIFICATIONS_READALL",
  // Help
  HELPS_GET: 'HELPS_GET',
  HELPS_SET: 'HELPS_SET',
  // token verification
  TOKEN_SEND_DATA: "TOKEN_SEND_DATA"
};

export default CONSTANTS;
