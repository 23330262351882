import CONSTANTS from "../CONSTANTS";

export const actionLogin = (user) => ({
  type: CONSTANTS.USERS_LOGIN,
  user,
});

export const actionLogout = () => ({
  type: CONSTANTS.USERS_LOGOUT,
});

export const actionShowLogoutModal = (val) => ({
  type: CONSTANTS.USERS_SHOW_LOGOUT_MODAL,
  val
});

export const actionLogoutBase = () => ({
  type: CONSTANTS.USERS_LOGOUT_BASE,
});

export const actionCheckSession = () => ({
  type: CONSTANTS.USERS_CHECK_SESSION,
});

export const actionRegister = (user) => ({
  type: CONSTANTS.USERS_REGISTER,
  user,
});

export const actionUserEdit = (user) => ({
  type: CONSTANTS.USERS_EDIT,
  user,
});

export const actionGetUser = () => ({
  type: CONSTANTS.USERS_GET,
});

export const actionSetUser = (user) => ({
  type: CONSTANTS.USERS_SET,
  user,
});

export const actionRefreshUser = (user) => ({
  type: CONSTANTS.USERS_REFRESH_DATA,
  user,
});

//Auth2Fa
export const actionCreate2fa = () => ({
  type: CONSTANTS.AUTH2FA_CREATE,
});

export const actionActivate2fa = (code) => ({
  type: CONSTANTS.AUTH2FA_ACTIVATE,
  code,
});

export const actionInactivate2fa = (pass) => ({
  type: CONSTANTS.AUTH2FA_INACTIVATE,
  pass,
});

export const actionCheck2fa = (code) => ({
  type: CONSTANTS.AUTH2FA_CHECK,
  code,
});

export const actionSetQr2fa = (qr) => ({
  type: CONSTANTS.AUTH2FA_SET,
  qr,
});

export const actionSetLoadingUsers = (loading) => ({
  type: CONSTANTS.USERS_LOADING,
  loading,
});
//Settings
// -- Language
export const actionUpdateLang = (language) => ({
  type: CONSTANTS.USERS_UPDATE_LANG,
  language,
});
export const actionSetLang = (language) => ({
  type: CONSTANTS.USERS_SET_LANG,
  language,
});
// -- DateFormat
export const actionSetDateFormat = (dateFormat) => ({
  type: CONSTANTS.USERS_SET_DATE_FORMAT,
  dateFormat,
});

export const actionUpdateDateFormat = (dateFormat) => ({
  type: CONSTANTS.USERS_UPDATE_DATE_FORMAT,
  dateFormat,
});

export const actionChangePassword = (user) => ({
  type: CONSTANTS.USERS_CHANGE_PASSWORD,
  user,
});

export const actionRecoverPassword = (email) => ({
  type: CONSTANTS.USERS_RECOVER_PASSWORD,
  email,
});

export const actionGetActiveSessions = () => ({
  type: CONSTANTS.USERS_GET_SESSIONS,
});

export const actionSetActiveSessions = (sessions) => ({
  type: CONSTANTS.USERS_SET_SESSIONS,
  sessions
});

export const actionDeleteSession = (session) => ({
  type: CONSTANTS.USERS_DELETE_SESSION,
  session
});

export const actionDeleteAccountRequest = () => ({
  type: CONSTANTS.USERS_DELETE_ACCOUNT_REQUEST,
});

export const actionDeleteAccountPrepare = (user) => ({
  type: CONSTANTS.USERS_DELETE_ACCOUNT_PREPARE,
  user
});

export const actionConfirmDeletion = (userId, choice) => ({
  type: CONSTANTS.USERS_DELETE_ACCOUNT,
  userId,
  choice
});

export const actionSendHelp = (email) => ({
  type: CONSTANTS.USERS_SEND_HELP,
  email,
});