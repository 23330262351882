import React, { useEffect, useCallback, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications
// import { ToastContainer, Flip } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import useSessionTimer from "../plugins/TS-lib-utils-public/hooks/useSessionTimer.jsx";

import routes from "routes.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { TranslatorProvider } from "react-translate";
import momentjsI18n from "helpers/momentjsI18n";
import { actionSetLang, actionSetDateFormat } from "store/actions/usersActions";
import { actionMobileVersion, actionNativeWebView, actionSweetAlert } from "store/actions/commonActions";
import history from 'historyRouter';
import { actionGetProfile } from "store/actions/profilesActions.js";
import { actionLogout, actionShowLogoutModal } from 'store/actions/usersActions';
import utilTranslations from '../helpers/utilTranslations';
import ScrollToTop from "./ScrollToTop.jsx";

var ps;

const Admin = (props) => {
  const dispatch = useDispatch();
  const { sweetAlert } = useSelector((state) => state.commonReducer);
  const { language, logoutModalActive } = useSelector((state) => state.userReducer);
  const t = utilTranslations(language || 'de', 'settings');
  const user = useSelector((state) => state.userReducer);
  const mainPanel = useRef();
  const adminNavRef = useRef();
  const isMounted = useRef(true);

  useSessionTimer(user);

  const updateMobile = () => {
    if (isMounted.current) {
      dispatch(actionMobileVersion(window.innerWidth < 992));
    }
  };

  useEffect(() => {
    isMounted.current = true;
    updateMobile();
    window.addEventListener("resize", updateMobile);

    dispatch(actionNativeWebView('ReactNativeSend' in window));

    window.addEventListener('resize', updateMobile);
    return () => {
      isMounted.current = false;
      window.removeEventListener("resize", updateMobile);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(actionGetProfile());
    if (((!user.is_ready && user.password_expired) || user.password_expired) && user.auth_2fa !== "pending") {
      history.push('/admin/settings');
    }
    if (user.on_delete) {
      history.push("/admin/delete-confirmation");
    }
  }, []);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let fullPage = document.getElementById("full-page");
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      // ps = new PerfectScrollbar(mainPanel.current);
      ps = new PerfectScrollbar(fullPage);
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);

  useEffect(() => {
    if (user._id) {
      momentjsI18n.setFormat(user.dateFormat);
      momentjsI18n.setLang(user.language);
      dispatch(actionSetLang(user.language));
      dispatch(actionSetDateFormat(user.dateFormat));
    }
    return () => null;
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (logoutModalActive) {
       dispatch(actionSweetAlert(
        <SweetAlert
          custom
          customIcon="/General/icons/warning-sign-orange.svg"
          title={t('are-you-sure-logout')}
          onConfirm={() => {}}
          customButtons={
            <>
              {/*Custom buttons for more control*/}
              <Button
                className="mb-3"
                color="primary"
                size="lg"
                onClick={() => {
                  dispatch(actionLogout());
                }}
              >
                {t('btn-yes')}
              </Button>

              <Button
                className="mb-3 ml-2"
                color="info"
                size="lg"
                onClick={() => {
                  dispatch(actionSweetAlert(null));
                  dispatch(actionShowLogoutModal(false));
                }}
              >
                {t('btn-no')}
              </Button>
            </>
          }
        ></SweetAlert>
      ));
    }
  }, [logoutModalActive]);

  const hideSidebar = () => adminNavRef.current.hideSidebar();

  const getRoutes = (routes) => {
    if (!user._id) {
      return <Redirect to="/auth" />;
    } else if (user.auth_2fa && user.auth_2fa === 'pending') {
      return <Redirect to="/auth/lock-screen-page" />;
    }
    /**
     * Password expired
     */
    if (user.password_expired) {
      const routeSettings = routes.find((route) => route.path === '/settings');
      if (!routeSettings) {
        throw new Error('Route no found!');
      }
      return [
        <Route
          path={routeSettings.layout + routeSettings.path}
          component={routeSettings.component}
          key={routeSettings.path}
        />,
      ];
    }

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (
    <TranslatorProvider
      translations={require(`assets/i18n/${language || "en"}.json`)}
    >
      {/* SweetAler Global */}
      {sweetAlert}
      {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
      /> */}
      <div className="wrapper animated fadeIn">
        <Sidebar
          {...props}
          routes={routes}
          hideSidebar={hideSidebar}
          backgroundColor="blue"
        />
        <AdminNavbar {...props} ref={adminNavRef} brandText={getActiveRoute(routes)} />
        <div className="main-panel admin_layout" id="full-page" ref={mainPanel}>
          <ScrollToTop mainPanel={mainPanel} />
          <Switch>
            {getRoutes(routes)}
            {/*(!user.is_ready || user.password_expired) && user.auth_2fa !== "pending" && <Redirect to="/admin/settings" />*/}
            <Redirect from="/admin" to={user.tokenInfo && user.is_ready ? "/admin/lab-studies" : "/admin/profile"} />
            {/* <Redirect from="/admin" to="/admin/welcome" /> */}
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
              <Footer fluid />
            )
          }
        </div>

        {/*
        <FixedPlugin
          handleMiniClick={minimizeSidebar}
          sidebarMini={sidebarMini}
          bgColor={backgroundColor}
          handleColorClick={handleColorClick}
        />
        */}
      </div>
    </TranslatorProvider>
  );
};

export default React.memo(Admin, (prevProps, nextProps) => {
  return prevProps?.location?.key === nextProps?.location?.key;
});
